import React, {useEffect, useState} from 'react';
import BoxWrapper from '../Wrapper/BoxWrapper';
import {IoIosArrowBack} from '@react-icons/all-files/io/IoIosArrowBack';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import {IoIosArrowForward} from '@react-icons/all-files/io/IoIosArrowForward';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
// import AppConfig from '../../config/appConfig';

/**
 *
 * @param {props} React.props
 * @param {Object} props.currentCategory - List of Categories item in Gatsby Query
 * @param {Object} props.categories - List of Categories item in Gatsby Query
 *
 * @returns
 */

const BlogCategories = ({currentCategory, categories}) => {
  // For slider ref
  const [sliderRef, setSliderRef] = useState(null);

  // Setting for Slick
  const settings = {
    dots: false,
    // focusOnSelect: true,
    infinite: true,
    // centerMode: true,
    speed: 500,
    variableWidth: true,
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: <IoIosArrowForward size={24} color="#6D6F7C" />,
    prevArrow: <IoIosArrowBack size={24} color="#6D6F7C" />,
    className: 'test',
  };

  // Get parent category to render list sub-category
  const parentCategory = categories[0].wpChildren.nodes.filter(
    (cate) => cate.uri !== '/blog' && currentCategory.uri.startsWith(cate.uri)
  )[0];

  // For GoTo Categories had Chosen before
  const handleClickGoTo = (id) => {
    return sliderRef?.slickGoTo(id);
  };

  const scrollToListItem = (selector, yOffset = 0) => {
    const el = document.querySelector(selector);
    console.log(el);
    console.log(el.getBoundingClientRect().top);
    console.log(window.pageYOffset);
    const y = el.getBoundingClientRect().top + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  };

  useEffect(() => {
    // let el = document.querySelector('ul.slider a.active');
    // console.log(el);
    // el?.scrollIntoView({behavior: 'smooth', inline: 'center'});
    // setTimeout(() => {
    //   let e = document.querySelector('div.slider');
    //   console.log(e);
    //   window.scrollTo({top: 300, behavior: 'smooth'});
    // }, 600);
    // scrollToListItem('div.slider', AppConfig.navStickyHeight);
  }, []);

  return (
    <>
      <BoxWrapper className="category-slider relative flex lg:w-full">
        <div className=" flex w-full flex-row items-center justify-start overflow-auto rounded-lg bg-white p-2 lg:justify-center ">
          <ul className="flex flex-row flex-nowrap items-center justify-center gap-2 whitespace-nowrap">
            <UniversalLink
              to={'/blog'}
              className={`${TailwindTransitions.default[200]} ${
                currentCategory.uri === '/blog'
                  ? 'active rounded-lg bg-primary/5 p-2  font-bold text-primary'
                  : 'text-[#6D6F7C]'
              }  ml-1 px-4 text-center hover:text-primary`}
            >
              Tất cả
            </UniversalLink>

            {categories[0].wpChildren.nodes.map((item, idx) => (
              <UniversalLink
                key={item.id}
                to={item.uri}
                onClick={() => handleClickGoTo(idx)}
                className={`${
                  item.uri !== '/blog' &&
                  currentCategory.uri.startsWith(item.uri)
                    ? 'active rounded-lg bg-primary/5 p-2 font-bold text-primary'
                    : 'text-[#6D6F7C] '
                } px-4 text-center last:mr-1 hover:text-primary`}
              >
                <li>{item.name}</li>
              </UniversalLink>
            ))}
          </ul>
        </div>
        {/* {scrollToListItem()} */}
      </BoxWrapper>

      {/* Name of Category */}
      <BoxWrapper className="mt-5 w-full flex-col items-center gap-5 text-center">
        <h2 className="text-2xl font-bold md:text-4xl">
          {currentCategory.name}
        </h2>
        <div className="h-2 w-16 rounded-tl rounded-tr bg-primary"></div>
      </BoxWrapper>

      {/* Sub-Categories */}
      {parentCategory?.wpChildren?.nodes?.length > 0 && (
        <BoxWrapper className="mt-5 w-full">
          <ul className="flex flex-nowrap items-center gap-5 overflow-x-auto whitespace-nowrap">
            <UniversalLink
              to={parentCategory.uri}
              className={`${TailwindTransitions.default[200]} ${
                currentCategory.id === parentCategory.id
                  ? 'border-primary font-medium text-primary'
                  : 'border-transparent'
              }  cursor-pointer border-b-[3px] hover:text-primary`}
            >
              Tất cả
            </UniversalLink>

            {/* Render sub-categories */}
            {parentCategory?.wpChildren?.nodes?.map((item) => (
              <UniversalLink
                key={item.id}
                to={item.uri}
                className={`
             ${
               currentCategory.uri.startsWith(item.uri)
                 ? 'border-primary font-medium text-primary'
                 : 'border-transparent'
             } ${
                  TailwindTransitions.default[200]
                } cursor-pointer border-b-[3px] hover:text-primary`}
              >
                <li>{item.name}</li>
              </UniversalLink>
            ))}
          </ul>
        </BoxWrapper>
      )}
    </>
  );
};

export default BlogCategories;
