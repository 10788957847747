import {graphql} from 'gatsby';
import React from 'react';
import Seo from '../components/Seo/Seo';
import PostCard from '../components/PostCard/PostCard';
import BoxGridWrapper from '../components/Wrapper/BoxGridWrapper';
import PageButtons from '../components/Pagination/PageButtons';
import BoxWrapper from '../components/Wrapper/BoxWrapper';
import {StaticImage} from 'gatsby-plugin-image';
import BlogCategories from '../components/BlogCategories/BlogCategories';

const ArchiveTemplate = ({
  data: {allWpPost, wpCategory},
  pageContext: {numPages, currentPage, currentCategory, categories},
}) => {
  let allWpPostFirst = [],
    allWpPostSecond = [];
  // Set feature post of number of post greater than 2
  if (allWpPost.nodes.length > 2) {
    //Take 2 post in array
    allWpPostFirst = allWpPost.nodes.slice(0, 2);
    // Take all post at index 2 to n
    allWpPostSecond = allWpPost.nodes.slice(2);
  } else {
    allWpPostSecond = allWpPost.nodes;
  }

  return (
    <div className="flex flex-col gap-4 bg-[rgb(247,248,250)] pt-[70px] md:gap-5 lg:gap-6 lg:pt-20">
      {/* Heading Section */}
      <BoxWrapper className="mt-1 lg:mt-5 lg:w-full">
        <div className="mt-5 flex flex-col items-center gap-5 rounded-xl  bg-[#4767ec1a] pt-8 md:flex-row md:py-0 md:pt-0 lg:w-full">
          <div className="flex w-full flex-col items-center justify-center gap-5 px-2 text-center md:w-[40%] md:items-start md:p-0 md:pl-8 md:text-left lg:pl-32">
            <h1 className="text-3xl font-bold md:text-4xl lg:text-6xl">Blog</h1>
            <p>Cập nhật tin tức mới</p>
          </div>

          <StaticImage
            src="../assets/blog/man-working-on-laptop.png"
            alt="Heading section image"
            className="w-full md:w-[60%]"
            objectFit="cover"
          />
        </div>
      </BoxWrapper>

      {/* Category Section */}
      <BlogCategories
        currentCategory={currentCategory}
        categories={categories}
      />

      {/* blog post first block */}
      {allWpPostFirst.length > 0 && (
        <BoxGridWrapper
          className="gap-5 lg:w-full"
          colsConfig={{default: 1, md: 1, lg: 2}}
        >
          {allWpPostFirst.map((post) => (
            <PostCard
              key={post.id}
              post={post}
              imgClassName={'w-full h-[200px] md:h-[300px]'}
            />
          ))}
        </BoxGridWrapper>
      )}

      {/* blog post second block */}
      <BoxGridWrapper className="gap-5" colsConfig={{default: 1, md: 2, lg: 4}}>
        {allWpPostSecond.map((post) => (
          <PostCard
            key={post.id}
            post={post}
            imgClassName={'w-full h-[150px]'}
          />
        ))}
      </BoxGridWrapper>

      {/* For Pagination */}
      <BoxWrapper className="mb-8 items-center justify-center md:mb-12">
        {numPages > 1 && (
          <PageButtons
            prefixPathName={wpCategory.uri}
            currentPage={currentPage}
            numPages={numPages}
          />
        )}
      </BoxWrapper>
    </div>
  );
};

export default ArchiveTemplate;

export const Head = ({data: {wpCategory}}) => {
  return (
    <Seo
      yoastSeo={wpCategory.seo}
      title={wpCategory.name}
      uri={wpCategory.uri}
      description={
        wpCategory.description && wpCategory.description.substring(150)
      }
    />
  );
};

export const pageQuery = graphql`
  query (
    $catId: String!
    $parentChildCategories: [String!] #Store ID of current & sub-categories
    $skip: Int!
    $limit: Int!
  ) {
    wpCategory(id: {eq: $catId}) {
      id
      uri
      name
      description
      seo {
        fullHead
        canonical
        breadcrumbs {
          text
          url
        }
      }
    }
    allWpPost(
      filter: {
        categories: {nodes: {elemMatch: {id: {in: $parentChildCategories}}}}
      }
      sort: {order: DESC, fields: date}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        excerpt
        uri
        slug
        date(formatString: "DD.MM.YYYY")
        tags {
          nodes {
            id
            name
            uri
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 170, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;
